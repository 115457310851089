<template>
  <v-container class="text-center mt-5"> </v-container>
</template>

<script>
export default {
  data() {
    return {
      onLine: navigator.onLine,
    };
  },
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|MAPP/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },
  },

  watch: {
    onLine: {
      handler: function (status) {
        if (!status) {
          this.$router.push("/offline");
          return;
        }
        if (!this.$store.state.settings.isListView) {
          if (this.isMobile) {
            this.$router.push("/map");
          } else {
            this.$router.push("/desktop");
          }
          return;
        } else {
          this.$router.push("/offline");
        }
      },
      immediate: true,
    },

    //"$store.state.settings.isListView": {
    //  handler: function (status) {
    //    console.log("HOME VIEW", status);
    //    if (!status) {
    //        if(this.isMobile) {
    //            this.$router.push('/map')
    //        } else {
    //            this.$router.push('/desktop')
    //        }
    //    } else {
    //        this.$router.push('/offline')
    //    }
    //  },
    //  immediate:true
    //}
  },

  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },

  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>
